import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import PasswordInput from "../../../components/Form/PasswordInput";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const PropertyManagementSiteSettings = ({
  hlsSftpUsername = "",
  hlsSftpPassword = "",
  hlsApiUsername = "",
  hlsApiPassword = "",
  handleHlsInputChange,
}) => {
  const [showHlsSettings, setShowHlsSettings] = useState(true);

  return showHlsSettings ? (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          data-testid="hls-sftp-username"
          variant="standard"
          fullWidth
          id="hlsSftpUsername"
          label="HLS SFTP Username"
          value={hlsSftpUsername || ""}
          onChange={event =>
            handleHlsInputChange("hlsSftpUsername")(event.target.value)
          }
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <PasswordInput
          id="hlsSftpPassword"
          inputProps={{ "data-testid": "hls-sftp-password" }}
          label="HLS SFTP Password"
          value={hlsSftpPassword || ""}
          onChange={event =>
            handleHlsInputChange("hlsSftpPassword")(event.target.value)
          }
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          data-testid="hls-api-username"
          variant="standard"
          fullWidth
          id="hlsApiUsername"
          label="HLS API Username"
          value={hlsApiUsername || ""}
          onChange={event =>
            handleHlsInputChange("hlsApiUsername")(event.target.value)
          }
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <PasswordInput
          data-testid="hls-api-password"
          id="hls-api-password"
          inputProps={{ "data-testid": "hls-api-password" }}
          label="HLS API Password"
          value={hlsApiPassword || ""}
          onChange={event =>
            handleHlsInputChange("hlsApiPassword")(event.target.value)
          }
        />
      </Grid>
    </>
  ) : null;
};

PropertyManagementSiteSettings.propTypes = {
  hlsSftpUsername: PropTypes.string,
  hlsSftpPassword: PropTypes.string,
  hlsApiUsername: PropTypes.string,
  hlsApiPassword: PropTypes.string,
  handleHlsInputChange: PropTypes.func.isRequired,
};

export default PropertyManagementSiteSettings;
