import { types, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";
import { truncate } from "lodash";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";
import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment SiteFullDetails on Site {
      id
      name
      POSHouseNumber
      justEatDiscountId
      justEatSiteId
      hlsSftpUsername
      hlsSftpPassword
      hlsApiUsername
      hlsApiPassword
      uberEatsStores {
        uberEatsStoreId
        status
      }
      resDiaryRestaurantId
      resDiaryRestaurantSecret
      minimumOrderSurchargePlu
      deliveryFeePlu
      deliverooDiscountId
      vitaMojoDiscountId
      collinsVenueId
      preorderSendTime
      POSServiceChargeId
      POSVersion
      loyaltyUrl
      loyaltyUsername
      loyaltyPassword
      loyaltyProfiles {
        id
        name
        discountId
      }
      networkId
      active
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
};

const LoyaltyProfile = types.model("LoyaltyProfile", {
  id: types.string,
  name: types.string,
  discountId: types.maybeNull(types.string),
});

const UberEatsStores = types.model("UberEatsStores", {
  uberEatsStoreId: types.string,
  status: types.string,
});

export default types
  .model("Site", {
    id: types.identifier,
    name: types.string,
    POSHouseNumber: types.number,
    justEatDiscountId: types.maybeNull(types.string),
    justEatSiteId: types.maybeNull(types.string),
    hlsSftpUsername: types.maybeNull(types.string),
    hlsSftpPassword: types.maybeNull(types.string),
    hlsApiUsername: types.maybeNull(types.string),
    hlsApiPassword: types.maybeNull(types.string),
    uberEatsStores: types.array(UberEatsStores),
    resDiaryRestaurantId: types.maybeNull(types.number),
    resDiaryRestaurantSecret: types.maybeNull(types.string),
    minimumOrderSurchargePlu: types.optional(types.string, ""),
    deliveryFeePlu: types.optional(types.string, ""),
    deliverooDiscountId: types.optional(types.string, ""),
    vitaMojoDiscountId: types.optional(types.string, ""),
    collinsVenueId: types.optional(types.string, ""),
    preorderSendTime: types.optional(types.string, ""),
    fluxStoreName: types.maybeNull(types.string),
    POSServiceChargeId: types.maybeNull(types.string),
    POSVersion: types.maybeNull(types.string),
    loyaltyUrl: types.maybeNull(types.string),
    loyaltyUsername: types.maybeNull(types.string),
    loyaltyPassword: types.maybeNull(types.string),
    loyaltyProfiles: types.array(LoyaltyProfile),
    networkId: types.number,
    active: types.boolean,
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get siteStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.siteStore);
    },
    get uberEatsStoresJoined() {
      return self.uberEatsStores
        ? truncate(
            self.uberEatsStores.map(item => item.uberEatsStoreId).join(", "),
          )
        : "";
    },
  }))
  .actions(self => {
    // const { apolloClient } = getEnv(self);

    return {
      setName(value) {
        self.name = value;
        return self;
      },

      setPOSHouseNumber(value) {
        self.POSHouseNumber = value;
        return self;
      },

      setJustEatDiscountId(value) {
        self.justEatDiscountId = value;
        return self;
      },

      setJustEatSiteId(value) {
        self.justEatSiteId = value;
        return self;
      },

      setHlsSftpUsername(value) {
        self.hlsSftpUsername = value;
        return self;
      },

      setHlsSftpPassword(value) {
        self.hlsSftpPassword = value;
        return self;
      },

      setHlsApiUsername(value) {
        self.hlsApiUsername = value;
        return self;
      },

      setHlsApiPassword(value) {
        self.hlsApiPassword = value;
        return self;
      },

      setUberEatsStores(value) {
        self.uberEatsStores = value;
        return self;
      },

      setResDiaryRestaurantId(value) {
        self.resDiaryRestaurantId = value;
        return self;
      },

      setResDiaryRestaurantSecret(value) {
        self.resDiaryRestaurantSecret = value;
        return self;
      },

      setMinimumOrderSurchargePlu(value) {
        self.minimumOrderSurchargePlu = value;
        return self;
      },

      setDeliveryFeePlu(value) {
        self.deliveryFeePlu = value;
        return self;
      },

      setDeliverooDiscountId(value) {
        self.deliverooDiscountId = value;
        return self;
      },

      setVitaMojoDiscountId(value) {
        self.vitaMojoDiscountId = value;
        return self;
      },

      setCollinsVenueId(value) {
        self.collinsVenueId = value;
        return self;
      },

      setPreorderSendTime(value) {
        self.preorderSendTime = value;
        return self;
      },

      setFluxStoreName(value) {
        self.fluxStoreName = value;
        return self;
      },

      setPOSServiceChargeId(value) {
        self.POSServiceChargeId = value;
        return self;
      },

      setPOSVersion(value) {
        self.POSVersion = value;
        return self;
      },

      setLoyaltyUrl(value) {
        self.loyaltyUrl = value;
        return self;
      },

      setLoyaltyUsername(value) {
        self.loyaltyUsername = value;
        return self;
      },

      setLoyaltyPassword(value) {
        self.loyaltyPassword = value;
        return self;
      },

      setLoyaltyProfiles(value) {
        self.loyaltyProfiles = value;
        return self;
      },

      setNetworkId(value) {
        self.networkId = value;
        return self;
      },

      setActive(value) {
        self.active = value;
        return self;
      },

      save: flow(function* save() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          name: self.name,
          POSHouseNumber: self.POSHouseNumber,
          justEatDiscountId: self.justEatDiscountId,
          justEatSiteId: self.justEatSiteId,
          hlsSftpUsername: self.hlsSftpUsername,
          hlsSftpPassword: self.hlsSftpPassword,
          hlsApiUsername: self.hlsApiUsername,
          hlsApiPassword: self.hlsApiPassword,
          uberEatsStores: self.uberEatsStores,
          resDiaryRestaurantId: self.resDiaryRestaurantId,
          resDiaryRestaurantSecret: self.resDiaryRestaurantSecret,
          minimumOrderSurchargePlu: self.minimumOrderSurchargePlu,
          deliveryFeePlu: self.deliveryFeePlu,
          deliverooDiscountId: self.deliverooDiscountId,
          vitaMojoDiscountId: self.vitaMojoDiscountId,
          collinsVenueId: self.collinsVenueId,
          preorderSendTime: self.preorderSendTime,
          fluxStoreName: self.fluxStoreName,
          POSServiceChargeId: self.POSServiceChargeId,
          POSVersion: self.POSVersion,
          loyaltyUrl: self.loyaltyUrl,
          loyaltyUsername: self.loyaltyUsername,
          loyaltyPassword: self.loyaltyPassword,
          networkId: self.networkId,
          active: self.active,
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateSite {
            updateSite(input: {
                ${params}
              }) {
                id
              }
          }
      `,
        });
      }),
      saveUberEats: flow(function* saveUberEats() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          uberEatsStores: self.uberEatsStores,
        });

        const response = yield apolloClient.mutate({
          mutation: gql`
          mutation updateSite {
            updateSite(input: {
                ${params}
              }) {
                id
                uberEatsStores {
                  uberEatsStoreId
                  status
                }
              }
          }
      `,
        });
        // update the store with the response
        if (response.data.updateSite) {
          self.setUberEatsStores(response.data.updateSite.uberEatsStores);
        }

        return response;
      }),
    };
  });
