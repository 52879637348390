import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";

import { GET_SITES } from "../helpers/apollo/utils";

import Select from "./Select";

const SiteSelector = ({ siteSelectCallback }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");

  const { error: getSitesQueryError } = useQuery(gql(GET_SITES()), {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ sites: sitesData }) => {
      if (sitesData.length > 0) {
        setSites(sitesData);

        // We default to the first site available in the list
        setSelectedSite(sitesData[0].id);
        siteSelectCallback(sitesData[0].id);
      }
    },
    variables: {
      sort: [
        {
          field: "name",
          direction: "ASC",
        },
      ],
    },
  });

  useEffect(() => {
    if (getSitesQueryError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg = getSitesQueryError?.message || fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occurred: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "site-selector-error-snackbar",
        },
      });
    }
  }, [getSitesQueryError, enqueueSnackbar]);

  return (
    <Grid container>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        md={10}
      >
        <Grid item xs={12} md={6} lg={3}>
          <Select
            label="Site"
            name="sites"
            onChange={({ target }) => {
              const { value: val } = target;
              setSelectedSite(val);
              siteSelectCallback(val);
            }}
            options={sites.map(({ id, name }) => ({
              text: name,
              value: id,
            }))}
            value={selectedSite}
            testId="sites-select"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

SiteSelector.propTypes = {
  siteSelectCallback: PropTypes.func.isRequired,
};

export default SiteSelector;
