import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import AddAction from "../Button/AddAction";
import NegativeAction from "../Button/NegativeAction";

const CustomToolbar = ({
  addAction,
  addLabel = "Add",
  deleteAction,
  deleteLabel = "Delete",
  // When true, this prop will disable the add button and enable the delete button
  isRowCreated,
}) => {
  return (
    <GridToolbarContainer sx={{ m: 1 }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: "Change density" } }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Grid item>
        <Box sx={{ marginLeft: "auto" }}>
          <Box sx={{ mr: 1, display: "inline" }}>
            <NegativeAction
              buttonText={deleteLabel}
              disabled={!isRowCreated}
              onClick={() => deleteAction()}
              testId="datagrid-custom-toolbar-delete-button"
            />
          </Box>
          <AddAction
            buttonText={addLabel}
            disabled={isRowCreated}
            onClick={() => addAction()}
            testId="datagrid-custom-toolbar-add-button"
          />
        </Box>
      </Grid>
    </GridToolbarContainer>
  );
};

CustomToolbar.propTypes = {
  addAction: PropTypes.func.isRequired,
  addLabel: PropTypes.string,
  deleteAction: PropTypes.func.isRequired,
  deleteLabel: PropTypes.string,
  isRowCreated: PropTypes.bool.isRequired,
};

export default CustomToolbar;
