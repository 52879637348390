import React, { useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import BulkEditor from "./components/BulkEditor";

const ServerSetup = ({ appStore }) => {
  const title = "Server Setup";

  useEffect(() => {
    appStore.setLoading(false);
  }, [appStore]);

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">{title}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              <BulkEditor />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

ServerSetup.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(ServerSetup));
