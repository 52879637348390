import React, { useEffect, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import NegativeAction from "../../components/Button/NegativeAction";
import PositiveAction from "../../components/Button/PositiveAction";

export const ERROR_MESSAGES = {
  required: {
    name: "Please specify organisation name",
    code: "Please specify organisation code",
    type: "Please specify organisation type",
    defaultBookingType: "Please select default booking type",
    POSPaymentType: "Please select POS payment type",
    orderMessageTimeoutMinutes: "Timeout must be 15 minutes or more",
  },
};

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1),
  },
  form: {
    padding: 12,
  },
  formControl: {
    maxWidth: 200,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { organisationId } = useParams();
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    type: "",
    POSPaymentType: null,
    defaultBookingType: null,
    orderMessageTimeoutMinutes: "",
  });
  const [organisation, setOrganisation] = useState(null);

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");

  useEffect(() => {
    Promise.allSettled([
      appStore.bookingTypeStore.findAll(),
      appStore.paymentTypeStore.findAll(),
    ]).then(() => {
      if (!organisationId) {
        appStore.setLoading(false);
      } else {
        appStore.organisationStore
          .find(organisationId)
          .then(apiOrganisation => {
            const {
              name,
              type,
              code,
              POSPaymentType,
              defaultBookingType,
              orderMessageTimeoutMinutes,
            } = apiOrganisation;

            setOrganisation(apiOrganisation);
            setFormData({
              name,
              type,
              code,
              POSPaymentType,
              defaultBookingType,
              orderMessageTimeoutMinutes,
            });

            appStore.setLoading(false);
          })
          .catch(error => {
            appStore.log.error(error);
            navigate("/app/404");
          });
      }
    });
  }, [organisationId]);

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/organisations");
  };

  const validateFormData = data => {
    const validationErrors = {};

    if (validator.isEmpty(data.name.toString())) {
      validationErrors.name = ERROR_MESSAGES.required.name;
    }

    if (validator.isEmpty(data.code.toString())) {
      validationErrors.code = ERROR_MESSAGES.required.code;
    }

    if (validator.isEmpty(data.type.toString())) {
      validationErrors.type = ERROR_MESSAGES.required.type;
    }

    if (
      data.orderMessageTimeoutMinutes &&
      data.orderMessageTimeoutMinutes < 15
    ) {
      validationErrors.orderMessageTimeoutMinutes =
        ERROR_MESSAGES.required.orderMessageTimeoutMinutes;
    }

    if (data.POSPaymentType == null) {
      validationErrors.POSPaymentType = ERROR_MESSAGES.required.POSPaymentType;
    }

    return validationErrors;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validationErrors = validateFormData(formData);

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();

      const {
        name,
        type,
        code,
        POSPaymentType,
        defaultBookingType,
        orderMessageTimeoutMinutes,
      } = formData;
      if (organisation === null) {
        appStore.organisationStore
          .add({
            name,
            type,
            code,
            POSPaymentType,
            defaultBookingType,
            orderMessageTimeoutMinutes,
          })
          .then(result => {
            navigate("/app/organisations");
            setOrganisation(result);
          })
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      } else {
        organisation.setName(name);
        organisation.setCode(code);
        organisation.setType(type);
        organisation.setPOSPaymentType(POSPaymentType);
        organisation.setDefaultBookingType(defaultBookingType);
        organisation.setOrderMessageTimeoutMinutes(
          orderMessageTimeoutMinutes !== "" ? orderMessageTimeoutMinutes : null,
        );

        organisation
          .save()
          .then(() => navigate("/app/organisations"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePOSPaymentTypeChange = ({ target: { value } }) => {
    setFormData({
      ...formData,
      POSPaymentType: appStore.paymentTypeStore.items.get(value),
    });
  };

  const handleDefaultBookingTypeChange = ({ target: { value } }) => {
    setFormData({
      ...formData,
      defaultBookingType: appStore.bookingTypeStore.items.get(value),
    });
  };

  const handleOrderMessageTimeoutChange = ({ target: { value } }) => {
    const parsedValue = value ? parseInt(value, 10) : value;

    if (value !== "" && Number.isNaN(parsedValue)) {
      setFormDataErrors({
        ...formDataErrors,
        orderMessageTimeoutMinutes:
          ERROR_MESSAGES.required.orderMessageTimeoutMinutes,
      });
    } else {
      setFormData({
        ...formData,
        orderMessageTimeoutMinutes: parsedValue,
      });
    }
  };

  const {
    name,
    type,
    code,
    POSPaymentType,
    defaultBookingType,
    orderMessageTimeoutMinutes,
  } = formData;

  return (
    <ErrorBoundary>
      <Page title={`${organisation !== null ? "Edit" : "Add"} organisation`}>
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate className={classes.form}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {queryError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{queryError}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "name" }}
                      name="name"
                      required
                      variant="standard"
                      fullWidth
                      label="Name"
                      value={name}
                      error={!!formDataErrors.name}
                      helperText={formDataErrors.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "code" }}
                      name="code"
                      required
                      variant="standard"
                      fullWidth
                      label="Code"
                      value={code}
                      error={!!formDataErrors.code}
                      helperText={formDataErrors.code}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formDataErrors.type}
                    >
                      <InputLabel required>Organisation Type</InputLabel>
                      <Select
                        inputProps={{
                          "aria-label": "type",
                        }}
                        name="type"
                        variant="standard"
                        value={type}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="">
                          <em>Select organisation type...</em>
                        </MenuItem>
                        {["POLARIS", "CLIENT", "INTEGRATION"].map(item => (
                          <MenuItem key={`pt-${item}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{formDataErrors.type}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formDataErrors.POSPaymentType}
                    >
                      <InputLabel required>POS Payment Type</InputLabel>
                      <Select
                        inputProps={{
                          "aria-label": "POSPaymentType",
                        }}
                        variant="standard"
                        value={POSPaymentType ? POSPaymentType.id : ""}
                        onChange={handlePOSPaymentTypeChange}
                      >
                        <MenuItem value="">
                          <em>Select payment type...</em>
                        </MenuItem>
                        {[...appStore.paymentTypeStore.items.values()].map(
                          item => (
                            <MenuItem key={`pt-${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                      <FormHelperText>
                        {formDataErrors.POSPaymentType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formDataErrors.defaultBookingType}
                    >
                      <InputLabel>Default Booking Type</InputLabel>
                      <Select
                        inputProps={{
                          "aria-label": "defaultBookingType",
                        }}
                        variant="standard"
                        value={defaultBookingType ? defaultBookingType.id : ""}
                        onChange={handleDefaultBookingTypeChange}
                      >
                        <MenuItem value="">
                          <em>Select booking type...</em>
                        </MenuItem>
                        {[...appStore.bookingTypeStore.items.values()].map(
                          item => (
                            <MenuItem key={`pt-${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                      <FormHelperText>
                        {formDataErrors.defaultBookingType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formDataErrors.orderMessageTimeoutMinutes}
                    >
                      <TextField
                        inputProps={{
                          "data-testid": "orderMessageTimeoutMinutes",
                        }}
                        variant="standard"
                        label="Order Message Timeout (Minutes)"
                        type="number"
                        value={orderMessageTimeoutMinutes}
                        onChange={handleOrderMessageTimeoutChange}
                        error={!!formDataErrors.orderMessageTimeoutMinutes}
                        helperText={formDataErrors.orderMessageTimeoutMinutes}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box pr={1} display="inline">
                      <NegativeAction
                        buttonText="Cancel"
                        onClick={handleCancel}
                      />
                    </Box>
                    <PositiveAction buttonText="Save" onClick={handleSubmit} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
